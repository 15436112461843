import React from 'react';
import { cn, withRef } from '@udecode/cn';
import { findNode } from '@udecode/plate-common';
import { PlateElement } from '@udecode/plate-common/react';


export const ParagraphElement = withRef<typeof PlateElement>(
  ({ className, children, editor, element, ...props }, ref) => {
    const isElementEmpty = element.children.length === 1 && element.children[0].text === '';

    const nodePath = findNode(editor, { at: [], match: { id: element.id } })![1];

    const activeSection = editor.children[nodePath[0]];
    const sectionChildren = activeSection.children.filter(child => !!child.type)

    const isFirstChild = sectionChildren[0].id === element.id;
    const isLastChild = sectionChildren[sectionChildren.length - 1].id === element.id;

    return (
      <PlateElement
        ref={ref}
        data-id={element.id as string}
        id={element.id as string}
        editor={editor}
        element={element}
        asChild
        className={cn(`
        px-0 text-base page-block paragraph-block 
        ${isFirstChild ? 'first-section-child' : ""} 
        ${isLastChild ? 'last-section-child' : ""}
        ${isElementEmpty ? 'placeholder-text' : ''}`,
          className)}
        {...props}
      >
        <p>{children}</p>
      </PlateElement>
    );
  }
);
