import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player/lazy'
import { Resizable } from "re-resizable";
import { PlateElement, useEditorRef } from '@udecode/plate-common/react';
import { useSelected } from 'slate-react';

import { Popover, PopoverContent, PopoverTrigger } from "../../../shared/Popover/Popover"
import TooltipIcon from '../../../shared/ToolTip/TooltipIcon';
import ToolbarDropdownSelector from '../../../shared/Toolbar/ToolbarDropdownSelector';
import { PlateVideoElement } from '../../../plate-config/Plugins/Video/Video.plugin';
import Icon from '../../../shared/Icon/Icon';

type Props = {
  element: PlateVideoElement
  ref: any,
  className?: string,
  isFirstChild: boolean,
  isLastChild: boolean,
  setEditMode: (mode: boolean) => void
  plateElProps: any
  children: React.ReactNode;
  isReadOnly: boolean;
}

const VideoPlayer: React.FC<Props> = ({
  ref, element, className, isFirstChild, isLastChild,
  setEditMode, plateElProps, isReadOnly, children
}) => {
  const editor = useEditorRef();
  const selected = useSelected()

  const [size, setSize] = useState({ width: element.width ? element.width : '100%' });
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(selected)
  }, [selected])

  const onSizeChange = (width: string) =>
    editor.setNodes(
      { width } as Partial<Node>,
      { at: [], match: (n: any) => { return n.id === element.id } }
    )

  const onAlignChange = (align: string) =>
    editor.setNodes(
      { align } as Partial<Node>,
      { at: [], match: (n: any) => { return n.id === element.id } }
    )

  const onEditClick = () => setEditMode(true)

  const onElementDelete = () => editor.removeNodes({ at: [], match: (n: any) => n.id === element.id })

  const alignOptions = [
    { name: 'left', label: <Icon icon="faAlignLeft" />, iconName: 'faAlignLeft' },
    { name: 'center', label: <Icon icon="faAlignCenter" />, iconName: 'faAlignCenter' },
    { name: 'right', label: <Icon icon="faAlignRight" />, iconName: 'faAlignRight' },
  ];

  const currentAlign = element.align ? element.align : 'left'
  const url = element.url as string
  const isLoomVideo = /loom\.com/i.test(url)
  const isVidyardVideo = /vidyard\.com/i.test(url)

  const isActive = !isReadOnly ? open : false

  return (
    <PlateElement
      ref={ref}
      id={element.id}
      editor={editor}
      data-id={element.id}
      element={element}
      className={`
        ${className} page-block video-block   
        ${isFirstChild ? 'first-section-child' : ""} 
        ${isLastChild ? 'last-section-child' : ""}
      `}
      data-plate-selectable
      contentEditable={false}
      suppressContentEditableWarning
      {...plateElProps}
    >
      <Popover open={isActive} onOpenChange={setOpen}>
        <PopoverTrigger className="w-full">
          <div
            className={`flex 
          ${element.align === 'left' ? 'justify-start' : ''}
          ${element.align === 'center' ? 'justify-center' : ''}   
          ${element.align === 'right' ? 'justify-end' : ''}
          `}>
            <Resizable
              defaultSize={{ width: size.width, height: '100%' }}
              onResize={(_e, _direction, ref) => setSize({ width: ref.style.width })}
              onResizeStop={(_e, _direction, ref) => onSizeChange(ref.style.width)}
              minWidth={'30%'}
              maxWidth={'100%'}
              className={`video-block-wrapper ${isActive ? 'active ring-2 ring-blue-400 ring-offset-1' : ''}`}
              handleClasses={{
                top: 'pointer-events-none', right: 'pointer-events-none',
                bottom: 'pointer-events-none', left: 'pointer-events-none',
              }}
              handleComponent={{
                bottomRight: <div className="resizer-control bottom-right" />,
                bottomLeft: <div className="resizer-control bottom-left" />,
                topRight: <div className="resizer-control top-right" />,
                topLeft: <div className="resizer-control top-left" />,
              }}
              enable={isReadOnly ? false : selected ? undefined : false}
            >
              <div className="video-player-wrapper aspect-video">
                {isLoomVideo || isVidyardVideo
                  ? <iframe
                    src={isLoomVideo ? url.replace(/share/i, "embed") : url}
                    allowFullScreen
                    style={{
                      position: "absolute", top: "0", left: "0",
                      width: "100%", height: "100%",
                    }}
                  />
                  : <ReactPlayer url={url} className="react-player" width="100%" height="100%" />
                }
              </div>
            </Resizable>
            {children}
          </div>
        </PopoverTrigger>
        <PopoverContent
          side="top"
          align="center"
          className={`
            flex p-1 w-auto border-solid border border-gray-200 rounded-xl bg-gray-100 text-gray-900 
            drop-shadow font-sans whitespace-nowrap bg-popover px-3 opacity-100 shadow-sm print:hidden 
          `}
        >
          <ToolbarDropdownSelector
            tooltip="Alignment"
            tooltipContainer={element.id}
            tooltipIcon={alignOptions.find((option) => option.name === currentAlign)!.iconName}
            onValueChange={onAlignChange}
            options={alignOptions}
            activeValue={element.align}
          />
          <TooltipIcon
            tooltip="Edit url"
            icon="faPen"
            iconWrapperClassName="btn-small rounded-lg hover:bg-white"
            side="top"
            container={element.id}
            onClick={onEditClick}
          />
          {/* Don't remove that div. It's a fix. */}
          <div />
          <TooltipIcon
            tooltip="Delete"
            icon="faTrash"
            iconWrapperClassName="btn-small rounded-lg hover:bg-red-100"
            side="top"
            container={element.id}
            onClick={onElementDelete}
          />
        </PopoverContent>
      </Popover>
    </PlateElement>
  )
}

export default VideoPlayer