import React, { useState } from "react";
import { Resizable } from "re-resizable";
import { isEditorReadOnly, useEditorRef } from "@udecode/plate-common/react";
import { useSelected } from "slate-react";

import { PlateElementEmbed } from "../../../../plate-config/Plugins/Embed/Embed.plugin";
import EmbedViewController from "../EmbedViewController/EmbedViewController";
import { EmbedConfig } from "../../config/config";


type Props = {
  element: PlateElementEmbed,
  platform: EmbedConfig,
  isActive: boolean
}

const ResizableEmbed: React.FC<Props> = ({ element, platform, isActive }) => {
  const editor = useEditorRef();
  const selected = useSelected()
  const isReadOnly = isEditorReadOnly(editor)

  const [size, setSize] = useState({ width: element.width ? element.width : '100%' });

  const onSizeChange = (width: string) =>
    editor.setNodes(
      { width, width_mode: null } as Partial<Node>,
      { at: [], match: (n: any) => { return n.id === element.id } }
    )

  return (
    <Resizable
      defaultSize={{ width: size.width, height: '100%' }}
      onResize={(_e, _direction, ref) => setSize({ width: ref.style.width })}
      onResizeStop={(_e, _direction, ref) => onSizeChange(ref.style.width)}
      minWidth={'25%'}
      maxWidth={'100%'}
      className={`embed-block-wrapper grid ${isActive ? 'active ring-2 ring-blue-400 ring-offset-1' : ''}`}
      handleClasses={{
        top: 'pointer-events-none', right: 'pointer-events-none',
        bottom: 'pointer-events-none', left: 'pointer-events-none',
      }}
      handleComponent={{
        bottomRight: <div className="resizer-control bottom-right" />,
        bottomLeft: <div className="resizer-control bottom-left" />,
        topRight: <div className="resizer-control top-right" />,
        topLeft: <div className="resizer-control top-left" />,
      }}
      enable={isReadOnly ? false : selected ? undefined : false}
    >
      <EmbedViewController platform={platform} element={element} />
    </Resizable>
  )
}

export default ResizableEmbed;