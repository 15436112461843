import React from 'react';
import { withRef } from '@udecode/cn';
import { findNode } from '@udecode/plate-common';
import { PlateElement, findNodePath, isEditorReadOnly } from '@udecode/plate-common/react';
import { useSelected } from 'slate-react';

import IconButton from '../../shared/IconButton/IconButton';
import { createDefaultButtonElement } from '../../plate-config/Plugins/Button/Button.plugin';
import { PlateButtonGroupElement } from '../../plate-config/Plugins/Button/ButtonGroup.plugin';
import { useAppSelector } from '../../store/hooks/redux-hooks';
import { selectedBlockClasses } from '../../utils/color.util';

const ButtonGroupElement = withRef<typeof PlateElement>(
  ({ children, editor, element, className, ...props }, ref) => {
    const block = element as PlateButtonGroupElement
    const isReadOnly = isEditorReadOnly(editor)
    const selected = useSelected()

    const nodePath = findNode(editor, { at: [], match: { id: element.id } })![1];
    const activeSection = editor.children[nodePath[0]];
    const sectionChildren = activeSection.children.filter(child => !!child.type)
    const isFirstChild = sectionChildren[0].id === element.id;
    const isLastChild = sectionChildren[sectionChildren.length - 1].id === element.id;
    // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
    // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
    const triggerToUpdate = useAppSelector(state => state.page_sections.sections)

    const onButtonAdd = () => {
      const buttonRowPath = findNodePath(editor, block)!
      editor.insertNodes(createDefaultButtonElement(), { at: [...buttonRowPath, children.length] })
    }

    return (
      <PlateElement
        ref={ref}
        id={block.id}
        editor={editor}
        element={block}
        className={`
        page-block button-group-block 
        ${isFirstChild ? 'first-section-child' : ""} 
        ${isLastChild ? 'last-section-child' : ""}
        ${!isReadOnly && selected ? isLastChild ? `${selectedBlockClasses} pb-4` : selectedBlockClasses : ""}
        ${className}`}
        data-plate-selectable
        data-id={block.id}
        {...props}
      >
        <div className={`
        flex max-w-full items-center gap-2 group/add-button relative
        ${block.align === 'center' ? 'justify-center' : ''}
        ${block.align === 'right' ? 'justify-end' : ''}
        `} >
          {children}
          {!isReadOnly && selected && children.length < 3 && (
            <IconButton
              onClick={onButtonAdd}
              icon='faPlus'
              btnClassName={`btn btn-white rounded-full flex justify-center w-[30px] h-[30px] p-[0px] ${!isLastChild ? "mb-4" : ""}`}
            />
          )}
        </div>
      </PlateElement>
    )
  })

export default ButtonGroupElement
