import React, { useCallback, useEffect, useState } from "react";
import { findNode } from "@udecode/plate-common";
import { PlateRenderElementProps, useEditorRef, isEditorReadOnly } from "@udecode/plate-common/react";
import ahoy from "ahoy.js"
import { useSelected } from "slate-react";

import { colorClassToRGB, selectedBlockClasses } from "../../utils/color.util";
import { useAppSelector } from "../../store/hooks/redux-hooks";
import LockScreen from "./LockScreen/LockScreen";
import EditableScreen from "./EditableScreen/EditableScreen";
import { PlateAssessmentElement } from "../../plate-config/Plugins/Assessment/Assessment.plugin";
import AssessmentToolbar from "./AssessmentToolbar/AssessmentToolbar";
import { AssessmentStyleTypes, calculateAndFormat } from "../../utils/calc_plugins.util";
import { StoreCalcVariable } from "../../store/reducers/page_calc_variables/page_calc_variables";

const AssessmentVoidElement = ({
  attributes,
  children,
  element,
}: PlateRenderElementProps) => {
  const editor = useEditorRef()
  const isReadOnly = isEditorReadOnly(editor)
  const selected = useSelected()

  const analyticsEnabled = useAppSelector(state => state.page_side_effects.analyticsEnabled)
  const { activePalette } = useAppSelector(state => state.page_colors)
  const { pageId, account_id, currency } = useAppSelector(state => state.page_addendums)
  // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
  // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
  const triggerToUpdate = useAppSelector(state => state.page_sections.sections)

  const page_calc_variables = useAppSelector(state => state.page_calc_variables)
  const shadow_store_variables = useAppSelector(state => state.page_shadow_store.calculation_variables)
  const calc_variables = isReadOnly ? shadow_store_variables : page_calc_variables

  const [lockedScreen, setLockedScreen] = useState(false)

  const lockScreenToggle = () => setLockedScreen(!lockedScreen)
  const block = element as PlateAssessmentElement

  useEffect(() => {
    if (isReadOnly) {
      setLockedScreen(block.assessment_detail_attributes.roi_locked ? !!block.assessment_detail_attributes.roi_locked_variable_id : lockedScreen)
    }
  }, [isReadOnly])

  const node = findNode(editor, { at: [], match: { id: block.id } })!
  const section = editor.children[node[1][0]]
  const sectionChildren = section.children.filter(child => !!child.type)
  const isFirstChild = sectionChildren[0].id === element.id;
  const isLastChild = sectionChildren[sectionChildren.length - 1].id === element.id;

  const sliderStoreValue = calc_variables.find((variable) => variable.id === block.assessment_detail_attributes.slider_variable_id)?.default || 0

  const trackAssessmentExplore = useCallback(() => {
    if (!analyticsEnabled) return
    console.log("Tracking assessment explore", {
      account_id,
      page_id: pageId,
      section_id: section.id,
      block_id: block.id,
      type: "assessment_explore_value",
    });
    return ahoy.track("page action", {
      account_id,
      page_id: pageId,
      section_id: section.id,
      block_id: block.id,
      type: "assessment_explore_value",
    });
  }, [pageId, section, block, account_id]);

  const trackAssessmentUnlocked = useCallback((answer: number) => {
    if (!analyticsEnabled) return
    const variable = calc_variables.find((variable) => variable.id === block.assessment_detail_attributes.roi_locked_variable_id)!
    const display_answer = (variable?.prefix ? variable.prefix + " " : "") + answer + (variable?.suffix ? " " + variable.suffix : "")
    console.log("Tracking assessment unlocked", {
      account_id,
      page_id: pageId,
      section_id: section.id,
      block_id: block.id,
      type: "assessment_unlocked",
      assessment_detail_id: block.assessment_detail_attributes.id,
      answer,
      display_answer,
      variable_id: variable.id,
      question: block.assessment_detail_attributes.roi_locked_label,
    });
    return ahoy.track("page action", {
      account_id,
      page_id: pageId,
      section_id: section.id,
      block_id: block.id,
      type: "assessment_unlocked",
      assessment_detail_id: block.assessment_detail_attributes.id,
      answer,
      display_answer,
      variable_id: variable.id,
      question: block.assessment_detail_attributes.roi_locked_label,
    });
  }, [pageId, section, block, calc_variables, account_id]);

  const trackAssessmentChanges = useCallback((
    field: string, variable_id: string | null, value: string | number,
    previous_value: string | number, sliderValue: number | null, variables: StoreCalcVariable[] | null
  ) => {
    if (!analyticsEnabled) return

    const stat_total = calculateAndFormat({
      formula: block.assessment_detail_attributes.stat_formula_attributes.calculation,
      variables: !!variables ? variables : calc_variables,
      sliderValue: typeof sliderValue === 'number' ? sliderValue : sliderStoreValue,
      type: 'number'
    });
    const price_total = calculateAndFormat({
      formula: block.assessment_detail_attributes.price_formula_attributes.calculation,
      variables: !!variables ? variables : calc_variables,
      sliderValue: typeof sliderValue === 'number' ? sliderValue : sliderStoreValue,
      type: 'currency',
      currency
    })
    const value_total = calculateAndFormat({
      formula: block.assessment_detail_attributes.value_formula_attributes.calculation,
      variables: !!variables ? variables : calc_variables,
      sliderValue: typeof sliderValue === 'number' ? sliderValue : sliderStoreValue,
      type: 'currency',
      currency
    })
    console.log("Tracking assessment change", {
      account_id,
      page_id: pageId,
      section_id: section.id,
      block_id: block.id,
      type: "assessment_change",
      field,
      variable_id,
      value,
      previous_value,
      stat_total,
      price_total,
      value_total,
    });
    return ahoy.track("page action", {
      account_id,
      page_id: pageId,
      section_id: section.id,
      block_id: block.id,
      type: "assessment_change",
      field,
      variable_id,
      value,
      previous_value,
      stat_total,
      price_total,
      value_total,
    })
  }, [pageId, section, block, currency, calc_variables, account_id]);

  const blockStyles: AssessmentStyleTypes = {
    '--assessment-plugin-background-color': colorClassToRGB(block.primary_color as string, block.assessment_detail_attributes.tint_opacity as number, activePalette.colors)!,
    zIndex: 1,
    ...(block.image_url
      ? {
        '--assessment-plugin-background-image': `url(${block.image_url})`,
        '--assessment-plugin-background-filter': `blur(${block.assessment_detail_attributes.background_blur}px)`,
        '--assessment-plugin-background-transform': `scale(${1 + (block.assessment_detail_attributes.background_blur as number / 200)})`,
        '--assessment-plugin-background-blend-mode': 'normal',
      }
      : {}),
  };

  return (
    <div
      className={`
      relative page-block assessment-block
      ${isFirstChild ? 'first-section-child' : ""} 
      ${isLastChild ? 'last-section-child' : ""}
      ${!isReadOnly && selected ? selectedBlockClasses : ""}
      `}
      data-plate-selectable
      contentEditable={false}
      suppressContentEditableWarning={true}
      {...attributes}
      data-id={block.id}
    >
      {children}
      <div className="assessment-plugin-wrapper overflow-auto flex flex-col rounded-xl w-full" style={blockStyles} id={block.id}>
        {!isReadOnly && <AssessmentToolbar element={block} lockScreenToggle={lockScreenToggle} />}
        <div className="px-6 py-8" >
          <div className="editor-assessment-container">
            {lockedScreen
              ? <LockScreen
                element={block}
                lockScreenToggle={lockScreenToggle}
                trackAssessmentUnlocked={trackAssessmentUnlocked}
                isEditorReadOnly={isReadOnly}
                calc_variables={calc_variables}
              />
              : <EditableScreen
                element={block}
                trackAssessmentExplore={trackAssessmentExplore}
                trackAssessmentChanges={trackAssessmentChanges}
                isEditorReadOnly={isReadOnly}
                calc_variables={calc_variables}
              />
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default AssessmentVoidElement
