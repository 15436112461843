import React, { useState } from "react";
import { useSelected } from "slate-react";
import { PlateElement, useEditorRef } from "@udecode/plate-common/react";
import validator from 'validator';

import TooltipIcon from "../../../shared/ToolTip/TooltipIcon";
import IconButton from "../../../shared/IconButton/IconButton";
import { PlateVideoElement } from "../../../plate-config/Plugins/Video/Video.plugin";
import { selectedBlockClasses } from "../../../utils/color.util";

type Props = {
  element: PlateVideoElement
  ref: any,
  className?: string,
  isFirstChild: boolean,
  isLastChild: boolean,
  setEditMode: (mode: boolean) => void
  plateElProps: any
  children: React.ReactNode;
};

const PreviewSelector: React.FC<Props> = ({
  ref, element, className, isFirstChild, isLastChild,
  setEditMode, plateElProps, children
}) => {
  const editor = useEditorRef();
  const selected = useSelected()
  const [url, setUrl] = useState(element.url ? element.url : '');
  const [error, setError] = useState<null | string>(null);

  const onInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
    setError(null);
  };

  const onConfirm = () => {
    if (validateVideoUrl(url)) {
      editor.setNodes({ url } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id });
      setEditMode(false);
    }
  };

  const validateVideoUrl = (url: string) => {
    if (!validator.isURL(url, { require_protocol: true })) {
      setError('Please enter a valid video URL');
      return false;
    }
    setError(null);
    return true;
  };

  const onElementDelete = () => editor.removeNodes({ at: [], match: (n: any) => n.id === element.id })

  const sources = ['Youtube', 'Vimeo', 'Loom', 'Vidyard', 'Facebook', 'Wistia', 'Twitch', 'Streamable', 'Dailymotion', 'Kaltura'];

  return (
    <PlateElement
      ref={ref}
      id={element.id}
      editor={editor}
      data-id={element.id}
      element={element}
      className={`
        ${className} page-block video-block  ${isFirstChild ? 'first-section-child' : ""}
        ${isLastChild ? 'last-section-child' : ""} ${selected ? selectedBlockClasses : ""}
        `}
      data-plate-selectable
      contentEditable={false}
      suppressContentEditableWarning
      {...plateElProps}
    >
      <div className="w-full min-h-96 bg-gray-200/80 border-gray-400 rounded-xl shadow-sm text-center p-8 pt-[37px]">
        <TooltipIcon
          tooltip="Delete"
          icon="faTrash"
          iconWrapperClassName="btn-small rounded-lg hover:bg-red-100 absolute text-gray-800 right-1 top-1"
          side="top"
          container={element.id}
          onClick={onElementDelete}
        />
        <div className={`form-group mb-3 ${error ? "error" : ""}`}>
          <div className="flex gap-2">
            <input
              type="text"
              className="form-control flex-grow w-full text-xs sm:text-xs md:text-base text-gray-700"
              placeholder="Insert video link"
              onChange={onInputChanged}
              value={url}
            />
            <IconButton
              btnClassName='btn btn-primary only-child'
              icon='faCheck'
              onClick={onConfirm}
            />
          </div>
          {error && <div className="form-hint text-left error">{error}</div>}
        </div>
        <div className="flex items-center justify-center">
          <div className="text-gray-800">
            <h3 className="mb-2">Upload a video from:</h3>
            <ul className="list-none text-base">
              {sources.map((provider, i) => <li key={i} className="pb-2">{provider}</li>)}
            </ul>
          </div>
        </div>
      </div>
      {children}
    </PlateElement>
  );
}

export default PreviewSelector;
