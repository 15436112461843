import React, { useEffect, useState } from "react"

import { PlateImageElement } from "../../../plate-config/Plugins/Image/Image.plugin"
import { Popover, PopoverContent, PopoverTrigger } from "../../../shared/Popover/Popover"
import ImageResizableBox from "./ImageResizableBox/ImageResizableBox"
import ImageLinkMenu from "./ImageToolbarMenu/ImageLinkMenu"
import TooltipIcon from "../../../shared/ToolTip/TooltipIcon"
import TooltipLink from "../../../shared/ToolTip/TooltipLink"
import ToolbarDropdownSelector from "../../../shared/Toolbar/ToolbarDropdownSelector"
import ImagePicker from "../../../shared/ImagePicker/ImagePicker"
import { useAppSelector } from "../../../store/hooks/redux-hooks"
import Icon from "../../../shared/Icon/Icon"

type Props = {
  element: PlateImageElement,
  onImageUnset: () => void
  setImageFromPicker: (image_url: string, image_source: string, image_external_id: string) => void
  onAlignChange: (align: string) => void
  onShapeChange: (shape: string) => void
  onUrlMenuChange: (url: string, link_type: string) => void
  onImageSizeChange: (width: string) => void
  isReadOnly?: boolean,
  onImageLinkResourceChange: (internal_resource_id: string) => void
  onImageResourceSlotChange: (internal_template_resource_slot_id: string) => void
  onLinkPageIdChange: (internal_page_id: string) => void
  isTemplate: boolean
  selected: boolean
}

const ProgrammaticImagePopover = ({
  element,
  onImageUnset,
  setImageFromPicker,
  onAlignChange,
  onShapeChange,
  onUrlMenuChange,
  onImageSizeChange,
  isReadOnly,
  onImageLinkResourceChange,
  onImageResourceSlotChange,
  onLinkPageIdChange,
  isTemplate,
  selected
}: Props) => {
  const [open, setOpen] = useState(false)
  useEffect(() => {
    setOpen(selected)
  }, [selected])
  const [showImagePicker, setShowImagePicker] = useState(false)
  const { pages } = useAppSelector(state => state.page_addendums)
  const { resources } = useAppSelector(state => state.page_resources)
  const isLandingPage = useAppSelector(state => state.page_addendums.isLandingPage)

  const { image_url, image_source } = element

  const matchingPage = pages?.find(page => page.id === element.internal_page_id)
  const matchingResource = resources?.find(resource => resource.id === element.internal_resource_id)

  const externalLinkPath = element.url && element.url.length > 0 ? element.url : null
  const internalLinkPath = matchingPage ? isLandingPage ? matchingPage.landing_link : matchingPage.builder_link : null
  const resourceLinkPath = matchingResource ? matchingResource.goto_url : null

  const goToLink = element.link_type === "external" ? externalLinkPath :
    element.link_type === "internal" ? internalLinkPath :
      element.link_type === "resource" ? resourceLinkPath : null

  const shapeOptions = [
    { name: 'square', label: <Icon icon="faSquare" />, iconName: 'faSquare' },
    { name: 'circle', label: <Icon icon="faCircle" />, iconName: 'faCircle' },
    { name: 'ellipse', label: <Icon icon="ellipse" />, iconName: 'ellipse' },
    { name: 'parallelogram', label: <Icon icon="parallelogram" />, iconName: 'parallelogram' },
    { name: 'triangle', label: <Icon icon="triangle" />, iconName: 'triangle' },
    { name: 'star', label: <Icon icon="faStar" />, iconName: 'faStar' },
  ];

  const alignOptions = [
    { name: 'left', label: <Icon icon="faAlignLeft" />, iconName: 'faAlignLeft' },
    { name: 'center', label: <Icon icon="faAlignCenter" />, iconName: 'faAlignCenter' },
    { name: 'right', label: <Icon icon="faAlignRight" />, iconName: 'faAlignRight' },
  ];

  const currentAlign = element.align ? element.align : 'left'
  const currentShape = element.shape ? element.shape : 'square'

  return (
    <>
      <Popover open={!isReadOnly ? open : false} onOpenChange={setOpen} >
        <PopoverTrigger className="w-full">
          <ImageResizableBox
            isPopoverOpen={open}
            element={element}
            onImageSizeChange={onImageSizeChange}
            isReadOnly={isReadOnly}
            goToLink={goToLink}
            selected={!isReadOnly ? open : false}
          />
        </PopoverTrigger>
        <PopoverContent
          side="top"
          align="center"
          id="image-element-tooltip-anchor"
          className={`
          flex p-1 w-auto border-solid border border-gray-200 rounded-xl bg-gray-100 text-gray-900 
          drop-shadow font-sans whitespace-nowrap bg-popover px-3 opacity-100 shadow-sm print:hidden 
          `}
        >
          <ImageLinkMenu
            element={element}
            onUrlChange={onUrlMenuChange}
            onImageResourceSlotChange={onImageResourceSlotChange}
            onImageLinkResourceChange={onImageLinkResourceChange}
            onLinkPageIdChange={onLinkPageIdChange}
          />
          {goToLink &&
            <TooltipLink
              tooltip="Follow link"
              side="top"
              container='image-element-tooltip-anchor'
              icon='faArrowUpRightFromSquare'
              path={goToLink}
              openInNewTab={(element.link_type === 'external' || element.link_type === 'resource')}
              linkClassName='btn-small hover:bg-gray-200 hover:rounded-sm'
            />
          }
          <ToolbarDropdownSelector
            tooltip="Alignment"
            tooltipContainer='image-element-tooltip-anchor'
            tooltipIcon={alignOptions.find((option) => option.name === currentAlign)!.iconName}
            onValueChange={onAlignChange}
            options={alignOptions}
            activeValue={element.align}
          />
          <ToolbarDropdownSelector
            tooltip="Shape"
            tooltipContainer='image-element-tooltip-anchor'
            tooltipIcon={shapeOptions.find((option) => option.name === currentShape)!.iconName}
            onValueChange={onShapeChange}
            options={shapeOptions}
            activeValue={element.shape}
          />
          <TooltipIcon
            tooltip="Change image"
            side="top"
            container="image-element-tooltip-anchor"
            onClick={setShowImagePicker.bind(setShowImagePicker, true)}
            iconWrapperClassName="btn-small rounded-lg hover:bg-white"
            icon="faImages"
          />
          {/* Don't remove that div. It's a fix. */}
          <div />
          <TooltipIcon
            tooltip="Delete"
            icon="faTrash"
            iconWrapperClassName="btn-small rounded-lg hover:bg-red-100"
            side="top"
            container="image-element-tooltip-anchor"
            onClick={onImageUnset}
          />
        </PopoverContent>
      </Popover>
      <ImagePicker
        backgroundImageSource={image_source as string}
        currentImageUrl={image_url as string}
        modalOpen={showImagePicker}
        onImageChange={setImageFromPicker}
        onImageUnset={onImageUnset}
        onModalClose={setShowImagePicker.bind(setShowImagePicker, false)}
        brandImages={!isTemplate}
      />
    </>
  )
}

export default ProgrammaticImagePopover