import React, { useEffect, useState } from "react";
import { PlateElement, useEditorRef } from "@udecode/plate-common/react";
import { useSelected } from "slate-react";

import { PlateElementEmbed } from "../../../plate-config/Plugins/Embed/Embed.plugin";
import { Popover, PopoverContent, PopoverTrigger } from "../../../shared/Popover/Popover"
import TooltipIcon from '../../../shared/ToolTip/TooltipIcon';
import ToolbarDropdownSelector from '../../../shared/Toolbar/ToolbarDropdownSelector';
import Icon from '../../../shared/Icon/Icon';
import { EmbedConfig, EmbedPlatform } from "../config/config";
import EmbedViewController from "./EmbedViewController/EmbedViewController";
import ResizableEmbed from "./ResizableEmbed/ResizableEmbed";
import { selectedBlockClasses } from "../../../utils/color.util";


type Props = {
  element: PlateElementEmbed
  ref: any,
  className?: string,
  isFirstChild: boolean,
  isLastChild: boolean,
  setEditMode: (mode: boolean) => void
  plateElProps: any
  children: React.ReactNode;
  isReadOnly: boolean;
  platform: EmbedConfig
}

const EmbedIframe: React.FC<Props> = ({
  ref, element, className, isFirstChild, isLastChild,
  plateElProps, children, setEditMode, isReadOnly, platform
}) => {
  const editor = useEditorRef();
  const selected = useSelected()

  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(selected)
  }, [selected])

  const onAlignChange = (align: string) =>
    editor.setNodes(
      { align } as Partial<Node>,
      { at: [], match: (n: any) => { return n.id === element.id } }
    )

  const onEditClick = () => setEditMode(true)

  const onElementDelete = () => editor.removeNodes({ at: [], match: (n: any) => n.id === element.id })

  const onWidthModeChange = (width_mode: string) =>
    editor.setNodes({ width_mode } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })

  const currentAlign = element.align ? element.align : 'left'
  const isActive = !isReadOnly ? open : false
  const nonResizablePlatforms = [EmbedPlatform.Facebook, EmbedPlatform.Twitter, EmbedPlatform.TikTok]
  const isNonResizable = nonResizablePlatforms.includes(platform.name)

  const sizeOptions = [
    { name: 'small', label: 'S' },
    { name: 'medium', label: 'M' },
    { name: 'large', label: 'L' },
  ];

  const alignOptions = [
    { name: 'left', label: <Icon icon="faAlignLeft" />, iconName: 'faAlignLeft' },
    { name: 'center', label: <Icon icon="faAlignCenter" />, iconName: 'faAlignCenter' },
    { name: 'right', label: <Icon icon="faAlignRight" />, iconName: 'faAlignRight' },
  ];

  return (
    <PlateElement
      ref={ref}
      id={element.id}
      editor={editor}
      data-id={element.id}
      element={element}
      className={`
        page-block embed-block ${className}
        ${isFirstChild ? 'first-section-child' : ""} 
        ${isLastChild ? 'last-section-child' : ""}
         `}
      data-plate-selectable
      contentEditable={false}
      suppressContentEditableWarning
      {...plateElProps}
    >
      <Popover open={isActive} onOpenChange={setOpen}>
        <PopoverTrigger className="w-full">
          <div
            className={`flex
          ${element.align === 'left' ? 'justify-start' : ''}
          ${element.align === 'center' ? 'justify-center' : ''}   
          ${element.align === 'right' ? 'justify-end' : ''}
          `}>
            {
              isNonResizable
                ?
                <div className={`${isActive ? selectedBlockClasses : ""} rounded-xl overflow-hidden`}>
                  <EmbedViewController platform={platform} element={element} />
                </div>
                :
                <ResizableEmbed element={element} platform={platform} isActive={isActive} />
            }
            {children}
          </div>
        </PopoverTrigger>
        <PopoverContent
          side="top"
          align="center"
          className={`
            flex p-1 w-auto border-solid border border-gray-200 rounded-xl bg-gray-100 text-gray-900 
            drop-shadow font-sans whitespace-nowrap bg-popover px-3 opacity-100 shadow-sm print:hidden 
          `}
        >
          <ToolbarDropdownSelector
            tooltip="Alignment"
            tooltipContainer={element.id}
            tooltipIcon={alignOptions.find((option) => option.name === currentAlign)!.iconName}
            onValueChange={onAlignChange}
            options={alignOptions}
            activeValue={element.align}
          />
          {platform.widthMode &&
            <ToolbarDropdownSelector
              tooltip="Width"
              tooltipContainer={element.id}
              tooltipIcon='faUpRightAndDownLeftFromCenter'
              onValueChange={onWidthModeChange}
              options={sizeOptions}
              activeValue={element.width_mode || 'large'}
            />}
          <TooltipIcon
            tooltip="Edit link"
            icon="faPen"
            iconWrapperClassName="btn-small rounded-lg hover:bg-white"
            side="top"
            container={element.id}
            onClick={onEditClick}
          />
          {/* Don't remove that div. It's a fix. */}
          <div />
          <TooltipIcon
            tooltip="Delete"
            icon="faTrash"
            iconWrapperClassName="btn-small rounded-lg hover:bg-red-100"
            side="top"
            container={element.id}
            onClick={onElementDelete}
          />
        </PopoverContent>
      </Popover>
      {children}
    </PlateElement>
  )
}

export default EmbedIframe