import React, { useState } from 'react';
import { withRef } from '@udecode/cn';
import { getParentNode, findNode, } from '@udecode/plate-common';
import { PlateElement, isEditorReadOnly } from '@udecode/plate-common/react';
import { Panel, PanelResizeHandle } from "react-resizable-panels";

import TooltipIcon from '../../shared/ToolTip/TooltipIcon';
import { nonSlashCommandsBlocks } from '../../utils/plate.util';
import { createDefaultParagraphElement } from '../../plate-config/Plugins/DefaultMockups/DefaultMockups';
import { useAppSelector } from '../../store/hooks/redux-hooks';

export const ColumnElement = withRef<typeof PlateElement>(
  ({ className, children, editor, element, ...props }, ref) => {
    const isReadOnly = isEditorReadOnly(editor)
    const [swapActive, setSwapActive] = useState(false)
    // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
    // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
    const triggerToUpdate = useAppSelector(state => state.page_sections.sections)
    const node = findNode(editor, { at: [], match: (n: any) => n.id === element.id })!
    const parentNode = getParentNode(editor, node[1])!

    const col_group = parentNode[0]
    const isLastCol = col_group.children[col_group.children.length - 1].id === element.id
    const isFirstCol = col_group.children[0].id === element.id

    const onColResize = (size: number, _prevSize: number | undefined) =>
      editor.setNodes({ width: Math.round(size) } as Partial<Node>, { at: [], match: (n: any) => n.id === element?.id })

    const onColumnSwap = () => {
      const path = [...node[1]]
      path[path.length - 1] += 1
      editor.moveNodes({ at: node[1], to: path })
    }

    const handleClick = (e: any) => {
      if (isReadOnly) return
      if (e.target === e.currentTarget) {
        const lastColumnChild = element.children[element.children.length - 1];
        const lastColumnChildType = lastColumnChild.type as string
        if (nonSlashCommandsBlocks.includes(lastColumnChildType)) {
          editor.insertNodes(createDefaultParagraphElement(), { at: [...node[1], element.children.length], select: true });
        }
      } else return
    };

    return (
      <>
        <Panel
          id={element.id as string}
          className={`
          panel_resizer page-block column-block ml-[-22px] pl-[22px]
          ${!isReadOnly
              ? `min-h-24 ${!isLastCol ? 'border-r-0' : ""} ${!isFirstCol ? 'border-l-0' : ''}
            ${col_group?.selected
                ? `border border-blue-400 border-opacity-50 ${isFirstCol ? 'rounded-l-lg' : ''} ${isLastCol ? 'rounded-r-lg' : ''}`
                : `border border-transparent group-hover/column-wrapper:border-blue-400 group-hover/column-wrapper:border-opacity-25
            ${!isLastCol ? 'group-hover/column-wrapper:border-r-0' : ""}
            ${isFirstCol ? 'rounded-l-lg' : ''} ${isLastCol ? 'rounded-r-lg' : ''}
            `}`
              : ""
            }`}
          onResize={onColResize}
          defaultSize={element.width as number}
          data-id={element.id}
          onClick={handleClick}
        >
          <PlateElement
            ref={ref}
            id={element.id as string}
            editor={editor}
            element={element}
            className="column"
            data-plate-selectable
            {...props}
          >
            {children}
          </PlateElement>
        </Panel>
        {!isReadOnly && !col_group.selected && !isLastCol &&
          <div
            className={`
          group-hover/column-wrapper:w-[1px] group-hover/column-wrapper::opacity-100
          group-hover/column-wrapper:bg-opacity-50  mr-[22px] group-hover/column-wrapper:outline-none
          group-hover/column-wrapper:rounded-sm  group-hover/column-wrapper:bg-blue-400
          `}
          />
        }
        {!isReadOnly && col_group.selected && !isLastCol &&
          <PanelResizeHandle
            disabled={isReadOnly || swapActive || !col_group.selected}
            className={`
            w-[1px] hover:opacity-100 bg-blue-400 bg-opacity-50 hover:bg-blue-600 mr-[22px]
            hover:bg-opacity-100 rounded-sm group/swapAnchor outline-none ${swapActive ? 'cursor-pointer' : 'cursor-col-resize'}
            `}
          >
            <TooltipIcon
              container={col_group.id as string}
              tooltip='Swap columns'
              side='top'
              icon='faRightLeft'
              onClick={onColumnSwap}
              iconClassName='cursor-pointer'
              iconWrapperClassName={`
                  btn btn-small opacity-0 group-hover/swapAnchor:opacity-100
                  btn-white rounded-full w-[30px] h-[30px] last-child flex
                  justify-center items-center absolute top-[50%] translate-x-[-50%]
                  translate-y-[-50%] cursor-pointer
                    `}
              onMouseEnter={setSwapActive.bind(setSwapActive, true)}
              onMouseLeave={setSwapActive.bind(setSwapActive, false)}
            />
          </PanelResizeHandle>
        }
        {isReadOnly && !isLastCol &&
          <div className="opacity-0 w-[1px] mx-2" />
        }
      </>
    );
  }
);
